<template>
  <div class="my-divider"></div>
</template>

<script></script>

<style scoped>
.my-divider {
  margin-bottom: 16px;
}
</style>
